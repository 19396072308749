'use strict';

{
	let rootClassname = `${window.namespace}-module`;
	let inputClassname = `${rootClassname}__input`
	let messageClassname = `${window.namespace}-module__messaging`;

	window.do.when('#contact-cta').then(cta => {
		let button = cta.querySelector(`.${window.namespace}-button[href]`);

		if(button)
		{
			let href = button.getAttribute('href');

			button.setAttribute('href', `${href}${location.search}`);

			button.addEventListener('click', click => {
				click.preventDefault();

				let region = document.getElementById('contact');
				
				if(region)
				{
					window.scrollToElement(region);
					router.setLocation(button.getAttribute('href'));

					let querySelector = `.${window.namespace}-section__content:not([hidden])`;
					let content = region.querySelector(querySelector);
					
					if(content)
					{
						window.setTimeout(() => {
							let formElements = content.querySelectorAll([
								`button:not([disabled]):not([hidden]):not([inert])`,
								`input:not([disabled]):not([hidden]):not([inert]):not([type='hidden']`,
								`select:not([disabled]):not([hidden]):not([inert])`,
								`textarea:not([disabled]):not([hidden]):not([inert])`
							].join(','));

							if(formElements.length)
							{
								formElements[0].focus();

								return;
							}

							let otherElements = content.querySelectorAll([
								`a[href]:not([hidden]):not([inert])`,
								`[tabindex]:not([tabindex='-1']):not([disabled]):not([hidden]):not([inert])`
							].join(','));

							otherElements.length && otherElements[0].focus();

							return;
						});
					}
				}

				return false;
			});
		}
	});
	
  //- ZIP Lookup Service
	window.do.when('#zip-lookup').then(module => {
		module.api = ((host) => {
			switch(host.toLowerCase())
			{
				case 'uspsconnect.com':
				case 'www.uspsconnect.com':
					return 'https://uspsconnect.com/bridge/lookup?zip5=';

				case 'localhost': 
				case 'localhost.content-updates.usps-connect.mrmclient.com':
          return 'http://api.localhost.usps-connect.mrmclient.com/bridge/lookup?zip5=';

        case 'bs-local.com': 
        case 'stage.content-updates.usps-connect.mrmclient.com':
				default:
					return 'https://stage.usps-connect.mrmclient.com/bridge/lookup?zip5=';
			}
		})(location.hostname);

    module.bypass = false;

    if(/(localhost|stage)\.?/gi.test(location.hostname)) {
      module.bypass = (query => {
        if(!!query) {
          query = '{"' + query.replace(/&/g, '","').replace(/=/g,'":"') + '"}';
          query = query.replace('{""}', '{}');
          query = JSON.parse(query, function(key, value) {
            return (key === "") ? value : decodeURIComponent(value)
          })

          if(query.date && /^\d{1,2}\/\d{1,2}\/(?:20)?\d{2}$/gi.test(query.date))
            return /\/(?:20)\d{2}$/gi.test(query.date) ? 
              moment(query.date, 'MM/DD/YYYY').format("MM/DD/YYYY") : 
              moment(query.date, 'MM/DD/YY').format("MM/DD/YYYY")

          return moment().format("MM/DD/YYYY")
        }
  
      })(location.search.substring(1))
    }

		window.do.always(() => {
			let selector = `${inputClassname}:not([data-assigned])`;

			if(document.querySelectorAll(`.${selector}`).length)
			{
				document.querySelectorAll(`.${selector}`).forEach(field => 
				{
					let module = field.closest(`.${rootClassname}`);

					field.validate = function()
					{
						let input = this.querySelector(`.${inputClassname}-native`);
						let pattern = (input.pattern) ? input.pattern : '^.+$';
						pattern = new RegExp(pattern, 'gi');

						if(!pattern.test(input.value))
						{
							
							field.classList.add(`${inputClassname}--invalid`);
						}
						
						else
						{
							field.classList.remove(`${inputClassname}--invalid`);
						}
					};

					{
						let input = field.querySelector(`.${inputClassname}-native`);
						
						if(input.value && input.value !== '')
						{
							input.classList.add(`${inputClassname}-native--filled`);
						}
						
						[
							'input',
							'blur'
						].forEach(eventListener => {
							input.addEventListener(eventListener, (event) => {
								if(input.value && input.value !== '')
								{
									field.classList.add(`${inputClassname}--dirty`);
									input.classList.add(`${inputClassname}-native--filled`);
								}
								
								else
								{
									input.classList.remove(`${inputClassname}-native--filled`);
								}

								input.value = input.value.replace(/[^\d|-]/gi, "");

								field.validate();
							});
						});
						
						let timer = null;
						let checkAvailability = () => {
							if(!input.dataset.loading || input.dataset.loading !== 'true')
							{
								let messaging = module.querySelector(`.${messageClassname}`);
								messaging.innerHTML = `<span class='material-icons-outlined' aria-hidden='true'>error_outline</span> Please enter a valid ZIP Code<sup>™</sup>`;

								if(!field.classList.contains(`${inputClassname}--invalid`))
								{
									input.dataset.loading = true;
									input.blur();
									
									input.setAttribute('readonly', '');
									messaging.innerHTML = `Loading`;

									axios
										.get(`${module.api}${input.value}`)
										.then(response => {

											//- This is the default state: Assume the ZIP is not in service.
											messaging.innerHTML = `<span class='material-icons-outlined' aria-hidden='true'>close</span> Sorry, we’re not in your area yet. We are continually launching in new markets, so please check back later to see if we’re in yours.`;	

											if(response.data.entries && Array.isArray(response.data.entries) && response.data.entries.length > 0)
											{
												let service = response.data.entries[0];
	
												//- Messaging for general service availability
												if(service.available)
												{
													messaging.innerHTML = `<span class='material-icons-outlined' aria-hidden='true'>check</span> Great news! We’ll be in your area soon.`;
													
													let friendlyDateString = moment(service.available, 'YYYY-MM-DD').format("MM/DD/YYYY")
													
													messaging.innerHTML = `<span class='material-icons-outlined' aria-hidden='true'>check</span> Great news! We’re in your area as of ${friendlyDateString}!`;

													if(moment(service.available).isSameOrBefore(module.bypass ? module.bypass : moment()))
													{
														messaging.innerHTML = `<span class='material-icons-outlined' aria-hidden='true'>check</span> Great news! We’re in your area.`;

                            if(service.improved_sdc && moment(service.improved_sdc).isSameOrBefore(module.bypass ? module.bypass : moment()))
                            {
                              messaging.innerHTML = `<span class='material-icons-outlined' aria-hidden='true'>check</span> Good news — You can enjoy more benefits of our reinvented network with a state-of-the-art Sorting and Delivery Center (S&amp;DC) in your area! This means greater reach for your business with USPS Connect Local’s ability to offer same-day or next-day delivery to more customers from a single drop-off point.`;
                            }
													}
												}
											}
											
											input.removeAttribute('readonly');
											input.removeAttribute('data-loading');
											input.focus();
										})
										.catch(err => {
											messaging.innerHTML = `<span class='material-icons-outlined' aria-hidden='true'>close</span> Sorry, something went wrong.`;
											
											messaging.removeAttribute('aria-hidden');
											messaging.removeAttribute('hidden');

											input.removeAttribute('readonly');
											input.removeAttribute('data-loading');
											input.focus();
										});
								}
							}
						};

						input.addEventListener('input', event => {
							clearTimeout(timer);

							timer = setTimeout(checkAvailability, 500);
						});
					}
					
					field.dataset.assigned = `${window.namespace}`;
				});
			}
		});
	});
}